/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CounterInput from "react-counter-input";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { MDBIcon } from "mdb-react-ui-kit";
//
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
//

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import { MDBCard, MDBCardBody, MDBCol, MDBListGroup, MDBListGroupItem, MDBRow } from "mdb-react-ui-kit";
import Currency from "./Currency";
import PackageCounter from "./PackageCounter";

import Alert from "react-bootstrap/Alert";
import StatusSteps from "./StatusSteps";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import Hlogo from "./../../../img/logo.png";
import { url } from "../../../api/api_url";
import { formatTime, formattedDateTime } from "../../../helper/dateformate";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CancelOrderListData(props) {
    const { myorderlist, layout } = props;
    const [creason, setCreason] = useState("");
    const [open, setOpen] = React.useState(false);
    const [show, setShow] = useState(false);
    const [sreason, setReason] = useState(false);
    const [errors, setErrors] = useState({});
    //   const history = useHistory();

    const validateForm = () => {
        let formIsValid = true;
        const errors = {};
    
        // Validate Email
        if (!creason) {
          errors.creason = "Enter your reason to cancel";
          formIsValid = false;
        }
    

    
        setErrors(errors);
        return formIsValid;
      };


    const handleClickOpen = () => {
        setReason(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClosed = () => {
        setReason(false);
    };
    const handelSubmite = () => {
        if (validateForm()) {
        setReason(false);
        setOpen(true);
        }
    };

    const notify = async (e) => {
        e.preventDefault();
        toast.error("Your Order Has been Cancelled!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setShow(true);
        setOpen(false);
        // setVreason(...vreason,{creason})

        if (validateForm()) {
        //api
        await fetch(`${url}/order/${myorderlist._id}?status=cancel`, {
            method: "PUT",
            body: JSON.stringify({ reason: creason }),
            headers: {
                "Content-type": "application/json",
                Authorization: localStorage.getItem("token"),
            },
        })
            .then((response) => response.json())
            .then((json) => {
                console.log(json);
            });
        }
    };
    console.log(creason);

    // const reasonChange = () => {
    //   setCreason();
    // }

    const date = new Date(myorderlist.createdAt);
    const date1 = new Date(myorderlist.serviceDate);
    // Extract the date and time components using Date methods
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    //
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth() + 1;
    const day1 = date1.getDate();
    const clmenuServices = myorderlist && myorderlist.services && myorderlist.services.map(item =>item.label);
    return (
        <div>
            <div className="product-card order-list-mu-style mrg-style">
                <div onClick={handleClickOpen}>
                <div className=" col-lg-12">
                    <div className=" row pl-4">
                        <div className="order-details-style" style={{display: "contents"}}>
                           
                            <div className="col-lg-4" style={{margin: "2% 0%"}}>
                                <MDBIcon fas icon="calendar-check" style={{ marginRight: "25px", fontSize: "24px" }} />
                                <span>Service Date</span> : {`${day1} -${month1} -${year1}`}
                            </div>

                            <div className="col-lg-4" style={{margin: "2% 0%"}}>
                                    <MDBIcon
                                        far
                                        icon="calendar-alt"
                                        style={{ marginRight: "25px", fontSize: "24px" }}
                                    />
                                    <span>Ordered Date</span> :{`${day} -${month} -${year}`}
                                </div>
                                <div className="col-lg-4" style={{margin: "2% 0%"}}>
                                    <MDBIcon
                                       far icon="clock" 
                                        style={{ marginRight: "25px", fontSize: "24px" }}
                                    />
                                    <span>Order Time</span> : {formatTime(myorderlist.createdAt)}
                                </div>

                                {myorderlist.services.map((item, index) => (
                                <div key={index} className="col-lg-4" style={{margin: "2% 0%"}}>
                                    <MDBIcon fas icon="boxes" style={{ marginRight: "25px", fontSize: "20px" }} />

                                    <span>
                                        {`${item.label} Count`} : {item.count}{" "}
                                    </span>

                                  
                                </div>
                            ))}
                            <div className="col-lg-4" style={{margin: "2% 0%"}}>
                                <div className="product-card__name" >
                                    <MDBIcon
                                        fas
                                        icon="utensils"
                                        style={{ marginRight: "25px", color: "#000", fontSize: "24px" }}
                                    />
                                    
                                    <div style={{ color: "#000" }} className="menu-txt">
                                    <span>{clmenuServices.join(', ')}</span>
                                    </div>
                                </div>
                            </div>





                        </div>
                       

                        {/* <div className=" col-lg-4 product-card__info order-details-style">
                            <div className="product-card__name txt-colr">
                                <div style={{ marginTop: "30px" }}>
                                    <MDBIcon
                                        far
                                        icon="calendar-alt"
                                        style={{ marginRight: "25px", fontSize: "24px" }}
                                    />
                                    <span>Ordered Date</span> :{`${day} -${month} -${year}`}
                                </div>
                                <div style={{ marginTop: "35px" }}>
                                    <MDBIcon
                                       far icon="clock" 
                                        style={{ marginRight: "25px", fontSize: "24px" }}
                                    />
                                    <span>Order Time</span> : {formatTime(myorderlist.createdAt)}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 product-card__info order-details-style">
                            {myorderlist.services.map((item, index) => (
                                <div key={index} style={{ marginTop: "26px" }}>
                                    <MDBIcon fas icon="boxes" style={{ marginRight: "25px", fontSize: "20px" }} />

                                    <span>
                                        {`${item.label} Count`} : {item.count}{" "}
                                    </span>

                                  
                                </div>
                            ))}
                           
                        </div> */}
                    </div>
                    </div>
                </div>
            </div>

            <Modal show={open} onHide={handleClose} className="safety-check-popup">
                <form>
                    <Modal.Body style={{ textAlign: "center", lineHeight: " 57px" }}>
                        <div>Are you Sure . Need to cancel this order ?</div>
                        <div>
                            <Button onClick={handleClose} className="safety-check-popup-btn mr-4" variant="primary">
                                No
                            </Button>
                            <Button onClick={notify} className="safety-check-popup-btn" variant="primary">
                                Yes
                            </Button>
                        </div>
                    </Modal.Body>
                </form>
            </Modal>

            <Modal show={sreason} onHide={handleClosed} className="safety-check-popup">
                <form>
                    <Modal.Header className="pop_head">
                        <h2 className="cancel-order-reason-tit">Order Cancel Reason</h2>

                        <Button className="cls-btn" style={{ color: "#fff" }} onClick={handleClosed}>
                            X
                        </Button>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: "center", lineHeight: " 57px" }}>
                        <div className="form-group">
                            <input
                                type="text"
                                value={creason}
                                name="creason"
                                className="form-control cancel-order-reason-input"
                                placeholder="Enter Your Reason"
                                onChange={(e) => setCreason(e.target.value)}
                                required
                            />
                             {errors.creason && <p style={{ color: "red" }}>{errors.creason}</p>}
                        </div>
                        <div>
                            <Button onClick={handelSubmite} className="safety-check-popup-btn" variant="primary">
                                Submit
                            </Button>
                        </div>
                    </Modal.Body>
                </form>
            </Modal>
        </div>
    );
}
