/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CounterInput from "react-counter-input";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { MDBIcon } from "mdb-react-ui-kit";
//
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Slide from "@mui/material/Slide";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import Select from "react-select";
import Chip from "@mui/material/Chip";
//
// import Multiselect from 'multiselect-react-dropdown';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
//
import { MDBCard, MDBCardBody, MDBCol, MDBListGroup, MDBListGroupItem, MDBRow } from "mdb-react-ui-kit";
import Currency from "./Currency";
import PackageCounter from "./PackageCounter";

import Alert from "react-bootstrap/Alert";
import StatusSteps from "./StatusSteps";
import Modal from "react-bootstrap/Modal";
import Hlogo from "./../../../img/logo.png";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from '@mui/material/FormControl';
import { formatTime, formattedDateTime } from "../../../helper/dateformate";
import { apiurl } from "../../../api/api_url";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function MenuChangeOrderList(props) {
    const { myorderlist, layout } = props;

    const [open, setOpen] = React.useState(false);
    const [show, setShow] = useState(false);
    const [reason, setReason] = useState(false);

    const [crmenu, setCrmenu] = useState("");
    const [chmenu, setChmenu] = useState([]);
    const theme = useTheme();

    const [currentService, setCurrentService] = React.useState();
    const [changeMenulistitem, setChangeMenulistitem] = React.useState();
    const [newmenu, setNewmenu] = useState();
    const [count, setCount] = useState(25);
    const [rschange, setRschange] = useState();

    const [menuListitem, setMenuListitem] = React.useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const [chnagemenu, setChangemenu] = useState([]);
    // const [rschange, setRschange] = useState("");
    // const [selectedService, setSelectedService] = useState(personName);
    const [menulistapi, setMenulistapi] = useState([]);
    const [selecteditem, setSelecteditem] = useState();
    const [options, setOptions] = useState();
    const [menuitemchose, setMenuitemchose] = useState(false);
    const [menuvalue, setMenuvalue] = React.useState(false);
    const [errors, setErrors] = useState({});



    const validateForm = () => {
        let formIsValid = true;
        const errors = {};
    
        // Validate Email
        if (!currentService) {
          errors.currentService = "Select your Service";
          formIsValid = false;
        }
    

    
        setErrors(errors);
        return formIsValid;
      };


    const handleChange4 = (e) => {
        setMenuvalue(e.target.value);
        console.log(e.target.value);
    };
    const handleClickOpen = () => {
        setReason(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClosed = () => {
        setReason(false);
    };

    useEffect(() => {
        getApiData();
        // eslint-disable-next-line
    }, []);

    const getApiData = async () => {
        // const { id } = props;
        // const { id } = useParams( myorderlist._id);
        const headers = { "Content-type": "application/json", Authorization: localStorage.getItem("token") };
        const result = await fetch(`${apiurl}/order/${myorderlist._id}`, { headers, method: "GET" }).then((response) =>
            response.json()
        );

        // update the state
        setMenulistapi(result.data);
        // console.log(result.data);
    };

    const notify = async (e) => {
        toast.success("Your Menu Change Request sended Successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setShow(true);
        setOpen(false);
        // setChmenu(...chmenu, { count, crmenu, newmenu, rschange });
        // counted('');
        // setChangemenu(...chnagemenu, { currentService, changeMenulistitem, newmenu, count,rschange });
        // console.log(e.target.value);
        if (validateForm()) {
        //api
        await fetch(`${apiurl}/menu-change/${myorderlist._id}`, {
            method: "PUT",
            body: JSON.stringify({
                count: count,
                price: selecteditem.price,
                changeItems: changeMenulistitem,
                newItems: newmenu,
                reasonChange: rschange,
                service: currentService,
                menuChange: menuvalue,
            }),
            headers: {
                "Content-type": "application/json",
                Authorization: localStorage.getItem("token"),
            },
        })
            .then((response) => response.json())
            .then((json) => {
                console.log(json);
            });
        }
    };

    // const option1 = [
    //     { value: "Idli ", label: "Idli " },
    //     { value: "Masala Dosa", label: "Masala Dosa" },
    //     { value: "Pongal", label: "Pongal" },
    //     { value: "Aloo Paratha", label: "Aloo Paratha" },
    //     { value: "Medu Vada", label: "Medu Vada" },
    //     { value: "Poori Masala", label: "Poori Masala" },
    //     { value: "Veg Biryani", label: "Veg Biryani" },
    // ];

    const toggleVisibility2 = (e) => {
        setIsVisible(!isVisible === true);
        // console.log(e.target.value);
    };

    const handleSelectChange = (e) => {
        setCurrentService(e.target.value);

        //

        const menus = menulistapi.services && menulistapi.services.map((item) => item.value);
        // const menulistfor = menus.find(item=> item.label);

        const selectedService = e.target.value;

        const filtered = menulistapi.services.filter((service) => {
            return service.value === e.target.value;
        });
        //   console.log(filtered );

        setSelecteditem(filtered[0]);
    };

    // console.log(selecteditem);

    const serviceValue =
        menulistapi.services &&
        menulistapi.services.map((item, index) => (
            <span key={index} value={item.value}>
                {item.label}
            </span>
        ));

    const servicemenuitems = selecteditem && selecteditem.menuItems.map((item) => item.label);

    const handleSelectChange1 = (selected) => {
        setChangeMenulistitem(selected);
    };

    const handleSelectChange3 = (e) => {
        setChangeMenulistitem(e.target.value);
    };

    const handleSelectChange2 = (e) => {
        setNewmenu(e.target.value);
    };

    const handleChange3 = (e) => {
        setRschange(e.target.value);
    };

    const handleChange2 = (e) => {
        let newCount2 = e.target.value;
        if (newCount2 < 0) {
            newCount2 = 25;
        }
        setCount(newCount2++);
        // if(count !== 25 ? 'minimum count 25': newCount2++){

        // }
    };

    const handelSubmite = () => {
        // setChangemenu(e.target.value);
        if (validateForm()) {
        setReason(false);
        setOpen(true);
        }
        // console.log(changeMenulistitem, count);
    };

    // console.log(chnagemenu&&chnagemenu);
    const date = new Date(myorderlist.createdAt);
    const date1 = new Date(myorderlist.serviceDate);
    // Extract the date and time components using Date methods
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    //
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth() + 1;
    const day1 = date1.getDate();

    const menuchange = myorderlist && myorderlist.services && myorderlist.services.map(item =>item.label);

    return (
        <>
            {/* {safetylist.map((product,index) => ( */}
           
            <div className="product-card order-list-mu-style mrg-style">
        
                <div onClick={handleClickOpen}>
                <div className=" col-lg-12">
                    <div className="  row pl-4">
                        <div  className=" order-details-style" style={{display: "contents"}}>
                            
                            <div className="col-lg-4" style={{margin: "2% 0%"}}>
                                <MDBIcon fas icon="calendar-check" style={{ marginRight: "25px", fontSize: "24px" }} />
                                <span>Service Date</span> : {`${day1} -${month1} -${year1}`}
                            </div>
                            <div className="col-lg-4" style={{margin: "2% 0%"}}>
                                    <MDBIcon
                                        far
                                        icon="calendar-alt"
                                        style={{ marginRight: "25px", fontSize: "24px" }}
                                    />
                                    <span>Ordered Date</span> :{`${day} -${month} -${year}`}
                                </div>
                                <div className="col-lg-4" style={{margin: "2% 0%"}}>
                                    <MDBIcon
                                       far icon="clock" 
                                        style={{ marginRight: "25px", fontSize: "24px" }}
                                    />
                                    <span>Order Time</span> : {formatTime(myorderlist.createdAt)}
                                </div>
                                {myorderlist.services.map((item, index) => (
                                <div key={index} className="col-lg-4" style={{margin: "2% 0%"}}>
                                    <MDBIcon fas icon="boxes" style={{ marginRight: "25px", fontSize: "20px" }} />

                                    <span>
                                        {`${item.label} Count`} : {item.count}{" "}
                                    </span>

                                    {/* <span>Breakfast Count</span> : 150 */}
                                </div>
                            ))}

                            <div  className="col-lg-4" style={{margin: "2% 0%"}}>
                                <div className="product-card__name" >
                                    <MDBIcon
                                        fas
                                        icon="utensils"
                                        style={{ marginRight: "25px", color: "#000", fontSize: "24px" }}
                                    />

                                    <div style={{ color: "#000" }} className="menu-txt">
                                    <span>{menuchange.join(', ')}</span>
                                    </div>
                                </div>
                            </div>
                          

                              


                        </div>

                    </div>
                    </div>
                </div>
            </div>
            {/* // ))} */}
            <Modal show={open} onHide={handleClose} className="safety-check-popup">
                <form>
                    <Modal.Body style={{ textAlign: "center", lineHeight: " 57px" }}>
                        <div>Are you Sure . Need to Change this Menu ?</div>
                        <div>
                            <Button onClick={handleClose} className="safety-check-popup-btn mr-4" variant="primary">
                                No
                            </Button>
                            <Button onClick={notify} className="safety-check-popup-btn" variant="primary">
                                Yes
                            </Button>
                        </div>
                    </Modal.Body>
                </form>
            </Modal>

            <Modal show={reason} onHide={handleClosed} className="safety-check-popup" style={{ marginTop: "30px" }}>
                <form>
                    <Modal.Header className="pop_head">
                        <h2 className="cancel-order-reason-tit">Enter Your New Menu</h2>

                        <Button className="cls-btn" style={{ color: "#fff" }} onClick={handleClosed}>
                            X
                        </Button>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: "center", lineHeight: " 57px" }}>
                        <div>
                            <div className="form-group">
                                <InputLabel id="demo-multiple-chip-label" style={{ textAlign: "left" }}>
                                    Current Service
                                </InputLabel>

                                
                                <select
                                    id="select-field"
                                    className="form-control menu-change-reason-input"
                                    style={{ padding: "10px" }}
                                    placeholder="Current Menu service"
                                    value={currentService}
                                    onChange={handleSelectChange}
                                >
                                    <option value="">--No changes in Service--</option>
                                    {menulistapi.services &&
                                        menulistapi.services.map((item, index) => (
                                            <option key={index} value={item.value}>
                                                {item.label}
                                            </option>
                                        ))}
                                </select>
                                {errors.currentService && <p style={{ color: "red" }}>{errors.currentService}</p>}
                              
                            </div>

                            {/* <div className="form-group">
                                <InputLabel id="demo-multiple-chip-label" style={{ textAlign: "left" }}>
                                    {" "}
                                    Change Menu Item ?
                                </InputLabel>
                               
                                <RadioGroup
                                    style={{ display: "contents", float: "left" }}
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={menuvalue}
                                    onChange={handleChange4}
                                >
                                    <FormControlLabel
                                        onChange={toggleVisibility2}
                                        value={true}
                                        control={<Radio />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        onChange={toggleVisibility2}
                                        value={false}
                                        control={<Radio />}
                                        label="No"
                                    />
                                </RadioGroup>

                               

                                {isVisible === true ? (
                                    <div>
                                        <br />
                                        <InputLabel id="demo-multiple-chip-label" style={{ textAlign: "left" }}>
                                            Menu Item to Change
                                        </InputLabel>
                                        <Select
                                            options={selecteditem && selecteditem.menuItems}
                                            name="changeMenulistitem"
                                            value={changeMenulistitem}
                                            onChange={handleSelectChange1}
                                            isMulti={true}
                                            className="  menu-change-reason-input txt-align-intial"
                                            placeholder="Menu item to be Change"
                                        />
                                    </div>
                                ) : null}
                               
                            </div> */}
                        </div>
                        {/* {isVisible === true ? (
                            <div className="form-group">
                                <InputLabel id="demo-multiple-chip-label" style={{ textAlign: "left" }}>
                                    New Menu
                                </InputLabel>

                                <input
                                    type="text"
                                    value={newmenu}
                                    name="newmenu"
                                    onChange={handleSelectChange2}
                                    className="form-control menu-change-reason-input"
                                    placeholder="Enter Your New Menu"
                                    required
                                />
                            </div>
                        ) : null} */}
                        <div className="form-group">
                            <InputLabel id="demo-multiple-chip-label" style={{ textAlign: "left" }}>
                                Enter Count
                            </InputLabel>
                            <input
                                //   disabled={count === 25 ? true : false}
                                className="form-control menu-change-reason-input"
                                type="number"
                                value={count}
                                onChange={handleChange2}
                            />
                        </div>
                        <div className="form-group">
                            <InputLabel id="demo-multiple-chip-label1" style={{ textAlign: "left" }}>
                                Reason Change
                            </InputLabel>
                            <input
                                type="text"
                                value={rschange}
                                name="rschange"
                                // onChange={(e) => setRschange(e.target.value)}
                                onChange={handleChange3}
                                className="form-control menu-change-reason-input"
                                placeholder="Enter your Reason  to Change"
                                required
                            />
                        </div>
                        <div>
                            <Button onClick={handelSubmite} className="safety-check-popup-btn" variant="primary">
                                Submit
                            </Button>
                        </div>
                    </Modal.Body>
                </form>
            </Modal>
        </>
    );
}

MenuChangeOrderList.propTypes = {
    /**
     * product object
     */
    myorderlist: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(["grid-sm", "grid-nl", "grid-lg", "list", "horizontal"]),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(MenuChangeOrderList);
