/* eslint-disable no-unused-vars */
// react
import React, { useState } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
//

import { MDBTabs, MDBTabsItem, MDBTabsLink, MDBTabsContent, MDBTabsPane, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { MDBIcon } from "mdb-react-ui-kit";

// application
import AsyncAction from "./AsyncAction";
import Currency from "./Currency";
import Rating from "./Rating";
import { cartAddItem } from "../../../store/cart";
import { Compare16Svg, Quickview16Svg, Wishlist16Svg } from "../../../svg";
import { compareAddItem } from "../../../store/compare";
import { quickviewOpen } from "../../../store/quickview";
import { url } from "../../../services/utils";
import { wishlistAddItem } from "../../../store/wishlist";
// import MonthlyMenu from "../site/MonthlyMenu";
import LoginBanner from "../../home/LoginBanner";
import { orderdetailsChange } from "../../../store/orderdetails";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { formatTime, formattedDateTime } from "../../../helper/dateformate";

function MyOrderListDelivery(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { myorderlist, product, layout, quickviewOpen, cartAddItem, wishlistAddItem, compareAddItem } = props;
    const containerClasses = classNames("product-card", "delivery-list-mu-style", "mrg-style", {
        "product-card--layout--grid product-card--size--sm": layout === "grid-sm",
        "product-card--layout--grid product-card--size--nl": layout === "grid-nl",
        "product-card--layout--grid product-card--size--lg": layout === "grid-lg",
        "product-card--layout--list": layout === "list",
        "product-card--layout--horizontal": layout === "horizontal",
    });

    console.log(myorderlist);

    const date = new Date(myorderlist.createdAt);
    const date1 = new Date(myorderlist.serviceDate);
    // Extract the date and time components using Date methods
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    //
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth() + 1;
    const day1 = date1.getDate();

    const menuDetails = () => {
        // dispatch(orderdetailsChange( myorderlist))

        // const menucountdata =
        // menudate.services.filter(item=> item.id=="your ID")

        // history.push("/site/my-order-count", menucountdata={ menudateid }) ;
        history.push("/site/delivered-orders/" + myorderlist._id);

        // console.log(menucountdata);
    };
    const dlmenuServices = myorderlist && myorderlist.services && myorderlist.services.map(item =>item.label);
    return (
        <div>
            <div className={containerClasses}>
                <div onClick={menuDetails}>
                <div className=" col-lg-12">
                    <div className=" row  pl-4">
                        <div className="order-details-style" style={{display: "contents"}} >
                            
                            <div  className="col-lg-4" style={{margin: "2% 0%"}}>
                                <MDBIcon fas icon="calendar-check" style={{ marginRight: "25px", fontSize: "24px" }} />
                                <span>Service Date</span> : {`${day1} -${month1} -${year1}`}
                            </div>
                            <div className="col-lg-4" style={{ margin: "2% 0%" }}>
                                    <MDBIcon
                                        far
                                        icon="calendar-alt"
                                        style={{ marginRight: "25px", fontSize: "24px" }}
                                    />
                                    <span>Ordered Date</span> : {`${day} -${month} -${year}`}
                                </div>



                                <div className="col-lg-4" style={{ margin: "2% 0%" }}>
                                    <MDBIcon
                                        far icon="clock" 
                                        style={{ marginRight: "25px", fontSize: "24px" }}
                                    />
                                    <span>Order Time</span> : {formatTime(myorderlist.createdAt)}
                                </div>
                                {myorderlist.services.map((item, index) => (
                                <div className="col-lg-4" key={index} style={{ margin: "2% 0%" }}>
                                    <MDBIcon fas icon="boxes" style={{ marginRight: "25px", fontSize: "20px" }} />
                                    <span>
                                        {`${item.label} Count`} : {item.count}{" "}
                                    </span>
                                </div>
                            ))}

                            <div   className="col-lg-4" style={{ margin: "2% 0%" }}>
                                <div className="product-card__name" >
                                    <MDBIcon
                                        fas
                                        icon="utensils"
                                        style={{ marginRight: "25px", color: "#000", fontSize: "24px" }}
                                    />
                                  
                                    <div style={{ color: "#000" }} className="menu-txt">
                                        {/* {myorderlist.services.map((item, index) => (
                                           */}
                                            <span >{dlmenuServices.join(', ')}  </span>
                                        {/* ))} */}
                                    </div>
                                </div>
                            </div>
                           
                                
                               

                        </div>
                      
                       
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

MyOrderListDelivery.propTypes = {
    /**
     * product object
     */
    myorderlist: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(["grid-sm", "grid-nl", "grid-lg", "list", "horizontal"]),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyOrderListDelivery);
