import React, { useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CounterInput from "react-counter-input";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { MDBIcon } from "mdb-react-ui-kit";
//
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
//

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//
import { MDBCard, MDBCardBody, MDBCol, MDBListGroup, MDBListGroupItem, MDBRow } from "mdb-react-ui-kit";
import Currency from "./Currency";
import PackageCounter from "./PackageCounter";

import Alert from "react-bootstrap/Alert";
import StatusSteps from "./StatusSteps";
import Modal from "react-bootstrap/Modal";
import Hlogo from "./../../../img/logo.png";
import { apiurl } from "../../../api/api_url";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function OrderDetailsList(props) {
    const { seftychecklist, layout } = props;
    const [open, setOpen] = React.useState(-1);
    //   const [show, setShow] = useState(false);
    // const[menulistapi,setMenulistapi]=useState([]);
    const handleClickOpen = (indexed) => {
        setOpen(indexed === open ? -1 : indexed);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // my api

    //  useEffect(() => {
    //   getApiData();
    // }, [])

    //   const getApiData = async () => {
    //       const headers = { "Content-type": "application/json",
    //       "Authorization":localStorage.getItem("token")
    //   }
    //       const response = await fetch(
    //           `${apiurl}/orders?list=ordered`,{ headers ,
    //               method: "GET"}
    //       ).then((response) => response.json());

    //       // update the state
    //       setMenulistapi(response.data);
    //     };
    // const foodsafety=menulistapi

    // console.log(seftychecklist);
    const date1 = new Date(seftychecklist.serviceDate);
    // Extract the date and time components using Date methods
    //   const year = date.getFullYear();
    //   const month = date.getMonth() + 1;
    //   const day = date.getDate();
    //   const hours = date.getHours();
    //   const minutes = date.getMinutes();
    //   const seconds = date.getSeconds();

    //
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth() + 1;
    const day1 = date1.getDate();

    const seftycheckservices = seftychecklist&&seftychecklist.services&&seftychecklist.services.map(item => item.label);
    // console.log(checked);

    return (
        <>
            {/* {safetylist.map((product,index) => ( */}

            <div className="product-card order-list-mu-style mrg-style">
                <div>
                    {/* <div   > */}
                    <div className=" col-lg-12">
                    <div className=" row pl-4">
                        <div className=" order-details-style" style={{display: "contents"}}>
                            {/* <div style={{fontSize: '24px',marginTop: '30px'}}><MDBIcon far icon="id-card" style={{marginRight: '25px'}}/>Order ID : {product.orderId}</div>  */}
                            <div className="col-lg-4" style={{margin: "2% 0%"}}>
                                <MDBIcon fas icon="calendar-check" style={{ marginRight: "25px", fontSize: "24px" }} />
                                <span>Service Date</span> : {`${day1} -${month1} -${year1}`}
                            </div>
                            <div className="col-lg-6" style={{margin: "3% 0%"}}>
                                <div className="product-card__name" >
                                    <MDBIcon
                                        fas
                                        icon="utensils"
                                        style={{ marginRight: "25px", color: "#000", fontSize: "24px" }}
                                    />
                                   
                                    <div className="menu-txt">
                                    <span>{seftycheckservices.join(' , ')}</span>
                                    </div>
                                </div>
                            </div>

                            {seftychecklist.services &&
                                seftychecklist.services.map((items, indexed) => (
                                    <div
                                        key={indexed}
                                        className=" product-card__name order-status-venu mt-top-4"
                                    >
                                        <div  className=" col-lg-4 count-ord-btn fd-sft" style={{margin: "3% 0%"}}>
                                            <span>{items.label} Safety Check : </span> 
                                            <Button
                                                onClick={() => handleClickOpen(indexed)}
                                                className={
                                                    items && items.covidcheck && items.covidcheck.status === false
                                                        ? "covid-delivered-detail-btn"
                                                        : "delivered-detail-btn"
                                                }
                                            >
                                                {" "}
                                                {items.covidcheck && items.covidcheck.status === true
                                                    ? "Completed"
                                                    : "Pending"}
                                            </Button>
                                        </div>
                                        {/* <div className="count-ord-btn"><span>{items.label } Safety Check : </span> <Button className= 'covid-delivered-detail-btn'  >{items.covidcheck && items.covidcheck.status?'Completed':'Pending'}</Button></div>  */}

                                        <Modal
                                            show={open === indexed}
                                            onHide={handleClose}
                                            className="safety-check-popup"
                                        >
                                            <form>
                                                <Modal.Body style={{ textAlign: "center", lineHeight: " 57px" }}>
                                                    <div>
                                                        COVID Check{" "}
                                                        {items.covidcheck && items.covidcheck.status === true
                                                            ? "Completed"
                                                            : "Pending"}
                                                       
                                                    </div>

                                                    <Button
                                                        onClick={handleClose}
                                                        className="safety-check-popup-btn"
                                                        variant="primary"
                                                    >
                                                        Ok
                                                    </Button>
                                                </Modal.Body>
                                            </form>
                                        </Modal>
                                    </div>
                                ))}


                        </div>
                        {/* <div className=" col-lg-6 product-card__info order-details-style">
                            {seftychecklist.services &&
                                seftychecklist.services.map((items, indexed) => (
                                    <div
                                        key={indexed}
                                        className="product-card__name order-status-venu food-safty-status-txt"
                                    >
                                        <div  className="count-ord-btn">
                                            <span>{items.label} Safety Check : </span>
                                            <Button
                                                onClick={() => handleClickOpen(indexed)}
                                                className={
                                                    items && items.covidcheck && items.covidcheck.status === false
                                                        ? "covid-delivered-detail-btn"
                                                        : "delivered-detail-btn"
                                                }
                                            >
                                                {" "}
                                                {items.covidcheck && items.covidcheck.status === true
                                                    ? "Completed"
                                                    : "Pending"}
                                            </Button>
                                        </div>
                                       
                                        <Modal
                                            show={open === indexed}
                                            onHide={handleClose}
                                            className="safety-check-popup"
                                        >
                                            <form>
                                                <Modal.Body style={{ textAlign: "center", lineHeight: " 57px" }}>
                                                    <div>
                                                        COVID Check{" "}
                                                        {items.covidcheck && items.covidcheck.status === true
                                                            ? "Completed"
                                                            : "Pending"}
                                                       
                                                    </div>

                                                    <Button
                                                        onClick={handleClose}
                                                        className="safety-check-popup-btn"
                                                        variant="primary"
                                                    >
                                                        Ok
                                                    </Button>
                                                </Modal.Body>
                                            </form>
                                        </Modal>
                                    </div>
                                ))}
                        </div> */}
                    </div>
                    </div>
                </div>
            </div>
         
        </>
    );
}
OrderDetailsList.propTypes = {
    /**
     * product object
     */
    seftychecklist: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(["grid-sm", "grid-nl", "grid-lg", "list", "horizontal"]),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailsList);
