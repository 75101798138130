/* eslint-disable no-unused-vars */
// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdb-react-ui-kit";

// application
import PageHeader from "../shared/PageHeader";

// blocks
import BlockMap from "../blocks/BlockMap";

// data stubs
import theme from "../../../data/theme";

function ContactUs() {
    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "Contact Us", url: "" },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{` ${theme.name} - Contact Us `}</title>
            </Helmet>

            <BlockMap />

            <PageHeader header="Contact Us" breadcrumb={breadcrumb} />

            <div className="block txt-colr ">
                <div className="container">
                    <div className="card mb-0">
                        <div className="card-body contact-us">
                            <div className="contact-us__container">
                                <div className="row">
                                    <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                                        <h4 className="contact-us__header card-title">Our Address</h4>
                                        <MDBCol className="mx-auto mb-md-0 mb-4">
                                            <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                                            
                                            <a href="#" className="adrs_txt">
                                                {" "}
                                                <p style={{ display: " flex" }}>
                                                    <MDBIcon icon="home" className="me-2 pr-4" />
                                                    <span>
                                                        The WorkVilla, 110, Lower Ground Floor, Uthamar Gandhi Rd,
                                                        Nungambakkam, Chennai, Tamil Nadu 600034
                                                    </span>
                                                </p>
                                            </a>
                                            <a href="#" className="adrs_txt">
                                                {" "}
                                                <p>
                                                    <MDBIcon icon="envelope" className="me-3 pr-4" />
                                                    support@hogist.com
                                                </p>
                                            </a>
                                            <a href="#" className="adrs_txt">
                                                {" "}
                                                <p>
                                                    <MDBIcon icon="phone" className="me-3 pr-4" /> +91 9962667733
                                                </p>
                                            </a>
                                        </MDBCol>

                                        {/* <div className="contact-us__address">
                                            <p>
                                            The WorkVilla, 110, Lower Ground Floor, Uthamar Gandhi Rd, Nungambakkam, Chennai, Tamil Nadu 600034
                                              
                                            </p>
                                            <p>  
                                                Email: support@hogist.com
                                               
                                              </p>
                                              <p>  Phone Number: +91 9962667733</p>

                                        </div> */}
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <h4 className="contact-us__header card-title">Leave us a Message</h4>

                                        <form>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-name">Your Name</label>
                                                    <input
                                                        type="text"
                                                        id="form-name"
                                                        className="form-control"
                                                        placeholder="Your Name"
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="form-email">Email</label>
                                                    <input
                                                        type="email"
                                                        id="form-email"
                                                        className="form-control"
                                                        placeholder="Email Address"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-subject">Subject</label>
                                                <input
                                                    type="text"
                                                    id="form-subject"
                                                    className="form-control"
                                                    placeholder="Subject"
                                                    required
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="form-message">Message</label>
                                                <textarea id="form-message" className="form-control" rows="4" required />
                                            </div>
                                            <button type="submit" className="btn btn-primary">
                                                Send Message
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ContactUs;
